




import {
  CAMERA_RECORDING_SUPPORTED,
  SCREEN_RECORDING_SUPPORTED
} from "@/store/modules/candidates/constants";
import Vue from "vue";
import { mapMutations } from "vuex";

export default Vue.extend({
  name: "InceptionRecuiterAssessmentWrapper",
  created() {
    this.set_screen_recording_support(true);
    this.set_camera_recording_support(true);
  },
  methods: {
    ...mapMutations("candidate", {
      set_screen_recording_support: SCREEN_RECORDING_SUPPORTED,
      set_camera_recording_support: CAMERA_RECORDING_SUPPORTED
    })
  }
});
